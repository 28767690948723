import { useStore } from '../state'
import ProjectDescriptionModal from './ProjectDescriptionModal'
import ProjectDescriptionMenu from './ProjectDescriptionMenu'

export function ProjectDescription() {
  const showProjectDescription = useStore(
    (state) => state.showProjectDescription
  )

  if (showProjectDescription) {
    return <ProjectDescriptionModal />
  } else {
    return <ProjectDescriptionMenu />
  }
}
