import { useGLTF } from '@react-three/drei'
import { Mesh, Object3D } from 'three'
import { getAssetPath } from '../assets'
import useModelLoadingProgressUpdate from '../hooks/useLoadingProgressUpdate'
import { ModelConfig } from '../services/projectService'

export type ModelProps = {
  config: ModelConfig
}

export function Model({ config }: ModelProps) {
  const modelPath = getAssetPath(config.modelName)
  const gltf = useGLTF(modelPath, true, true)

  // Update state one last time when loading the model has finished since the Fallback doesn't
  useModelLoadingProgressUpdate()

  gltf.scene.traverse((node: Object3D) => {
    if (node instanceof Mesh) {
      node.castShadow = true
      node.receiveShadow = true
    }
    // Undo any hiding of objects
    node.visible = true
  })

  config.hiddenObjects?.forEach((hiddenObject) => {
    const object = gltf.scene.getObjectByName(hiddenObject)
    if (object) {
      object.visible = false
    }
  })

  return <primitive object={gltf.scene} dispose={null} />
}
