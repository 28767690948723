import useModelLoadingProgressUpdate from '../hooks/useLoadingProgressUpdate'

function LoadingProgressUpdate() {
  // Set loading progress to global state so it can be used to control progress meter elsewhere in the UI
  useModelLoadingProgressUpdate()

  return null
}

export default LoadingProgressUpdate
