import React from 'react'
import styled from 'styled-components'
import { usePointOfInterests } from '../hooks/usePointOfInterests'
import { useStore } from '../state'

export function PoiMenu() {
  function PoiCarousel() {
    const pois = usePointOfInterests()
    const selectPoi = useStore((state) => state.api.selectPoi)
    const selectedPoiId = useStore((state) => state.selectedPoiId)

    function selectPreviousPoi() {
      const currentPoiIndex = pois.findIndex((poi) => poi.id === selectedPoiId)
      const lastPoiIndex = pois.length - 1
      const previousPoiIndex =
        currentPoiIndex <= 0 ? lastPoiIndex : currentPoiIndex - 1
      selectPoi(pois[previousPoiIndex].id)
    }

    function selectNextPoi() {
      const currentPoiIndex = pois.findIndex((poi) => poi.id === selectedPoiId)
      const lastPoiIndex = pois.length - 1
      const firstPoiIndex = 0
      const nextPoiIndex =
        currentPoiIndex >= lastPoiIndex ? firstPoiIndex : currentPoiIndex + 1
      selectPoi(pois[nextPoiIndex].id)
    }

    return (
      <PoiNavigationBar>
        <PreviousButton onClick={selectPreviousPoi}>Previous</PreviousButton>
        <NextButton onClick={selectNextPoi}>Next</NextButton>
      </PoiNavigationBar>
    )
  }

  return <PoiCarousel />
}

const PoiNavigationBar = styled.div`
  order: 3;
  padding: 15px 15px 17px;
  bottom: 0;
  line-height: 24px;
  font-weight: 500;
  text-decoration: none;
  border-radius: 0 0 12px 12px;
  background-color: ${(props) => props.theme.colors.background_poi_navigation};
`

const PreviousButton = styled.button`
  padding: 0;
  color: ${(props) => props.theme.colors.text_defocused};
  font-size: 16px;
  background: transparent;
  border: none;
  user-select: none;

  :hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.primary};
  }
`

const NextButton = styled.button`
  padding: 0;
  float: right;
  color: ${(props) => props.theme.colors.text_defocused};
  font-size: 16px;
  background: transparent;
  border: none;
  user-select: none;

  :hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.primary};
  }
`
