import create from 'zustand'

type State = {
  modelLoadingProgress: number
  modelLoadingActive: boolean
  activeSceneId?: number
  showProjectDescription: boolean
  selectedPoiId: number | undefined
  showPoiDescription: boolean

  api: {
    setModelLoadingProgress: (isActive: boolean, withPercentage: number) => void
    selectScene: (withId: number) => void
    toggleProjectDescription: () => void
    selectPoi: (withId: number) => void
    togglePoiDescription: () => void
    poiDescriptionOn: () => void
    poiDescriptionOff: () => void
  }
}

export const useStore = create<State>((set) => ({
  modelLoadingProgress: 0,
  modelLoadingActive: true,
  showProjectDescription: true,
  selectedPoiId: undefined,
  showPoiDescription: false,

  api: {
    setModelLoadingProgress: (isActive, withPercentage) =>
      set((state) => ({
        modelLoadingActive: isActive,
        modelLoadingProgress: withPercentage,
      })),
    selectScene: (withId) => set((state) => ({ activeSceneId: withId })),
    toggleProjectDescription: () =>
      set((state) => ({
        showProjectDescription: !state.showProjectDescription,
      })),
    selectPoi: (withId) => set((state) => ({ selectedPoiId: withId })),
    togglePoiDescription: () =>
      set((state) => ({ showPoiDescription: !state.showPoiDescription })),
    poiDescriptionOn: () => set((state) => ({ showPoiDescription: true })),
    poiDescriptionOff: () => set((state) => ({ showPoiDescription: false })),
  },
}))
