import React from 'react'
import styled from 'styled-components'
import { getAssetPath } from '../assets'

type ProjectImageProps = {
  image: string
}

const Image = styled.img`
  width: 100%;
  transition: opacity 1s ease-in;
`

export function ProjectImage({ image }: ProjectImageProps) {
  const imageSrc = getAssetPath(image)

  return <Image src={imageSrc} />
}
