import { Canvas } from '@react-three/fiber'
// eslint-disable-next-line
import { Stats, Sky } from '@react-three/drei'
import useWindowDimensions from '../hooks/useWindowDimensions'
import { usePointOfInterests } from '../hooks/usePointOfInterests'
import { PoiMarker } from './PoiMarker'
import Lights from './Lights'
import ViewControls from './ViewControls'
import ModelManager from './ModelManager'
// import { EffectComposer, DepthOfField } from '@react-three/postprocessing'
import { useStore } from '../state'
import styled, { css, keyframes } from 'styled-components'
import { useState } from 'react'

export function Scene() {
  const { height: windowHeight } = useWindowDimensions()
  const isLoading = useStore((state) => state.modelLoadingActive)
  const [finishedLoading, setLoaded] = useState(false)
  const animateFadeIn = !isLoading && !finishedLoading

  return (
    <StyledCanvas
      style={{ height: windowHeight, zIndex: 0 }}
      animate={animateFadeIn}
      onAnimationEnd={() => setLoaded(true)}
      // shadows
      camera={{
        fov: 45,
        near: 0.1,
        far: 5000,
        zoom: 1,
      }}
    >
      <color attach="background" args={[0xe5e5e5]} />
      <fog attach="fog" args={[0xe5e5e5, 3500, 5000]} />
      {/* <Sky
        distance={7000}
        sunPosition={[1, 0, -1]}
        inclination={1}
        azimuth={0.5}
        turbidity={2}
        rayleigh={0.2}
        mieCoefficient={0.06}
        mieDirectionalG={0.2}
      /> */}
      <Lights /> {/* showLightHelpers /> */}
      <PoiMarkers />
      <ModelManager />
      <ViewControls />
      {/* <Stats className={'stats'} />
      <axesHelper args={[10000]} /> */}
      {/* <EffectComposer>
        <DepthOfField
          focusDistance={0}
          focalLength={0.4}
          bokehScale={1.5}
          height={600}
        />
      </EffectComposer> */}
    </StyledCanvas>
  )
}

type StyledCanvasProps = {
  animate?: boolean
}

const StyledCanvas = styled(Canvas)<StyledCanvasProps>`
  opacity: 0;
  animation: ${(props) =>
    props.animate &&
    css`
      ${fadeIn} 2s forwards
    `};
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

function PoiMarkers() {
  const pois = usePointOfInterests()
  const showingProjectDescription = useStore(
    (state) => state.showProjectDescription
  )
  return (
    <>
      {showingProjectDescription
        ? null
        : pois.map((poi) => <PoiMarker key={poi.id} poi={poi} />)}
    </>
  )
}