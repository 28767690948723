import React from 'react'
import styled from 'styled-components'
import { useSelectedPointOfInterest } from '../hooks/usePointOfInterests'
import { useStore } from '../state'
import closeIcon from '../assets/images/close.svg'
import { PoiNote } from './PoiNote'
import { PoiMenu } from './PoiMenu'

const PoiDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  width: 450px;
  height: 97%;
  margin: 12px 12px 12px 0;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.theme.colors.border};
  border-radius: 12px;
  background-color: ${(props) => props.theme.colors.background_overlay};
  text-align: left;

  @media screen and (max-width: 479px) {
    width: 94%;
    height: 96%;
  }
`

const PoiContent = styled.div`
  /* height: 83.8%; */
  padding: 0 12px 12px;
  border-bottom: solid;
  border-width: 1px;
  border-color: ${(props) => props.theme.colors.border};
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (max-height: 680px) {
    /* height: 75.85%; */
  }

  @media screen and (min-height: 681px) and (max-height: 970px) {
    /* height: 82.4%; */
  }
`

const PoiHeader = styled.div``

const PoiName = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 27px;
  margin: 12px;
  color: ${(props) => props.theme.colors.primary};
  width: 90%;
`

const CloseButton = styled.button`
  position: absolute;
  top: 6px;
  right: 12px;
  width: 37px;
  height: 37px;
  margin-top: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.theme.colors.border};
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.background_buttons};
  padding: 3px 6px;

  :hover {
    background-color: ${(props) => props.theme.colors.secondary_transparent};
    cursor: pointer;
  }
`

const CloseButtonImage = styled.img`
  width: 24px;
  margin-top: 3px;
`

const PoiLocation = styled.p`
  font-size: 15px;
  margin: 12px;
  color: ${(props) => props.theme.colors.text};
  height: 21px;
`

const PoiText = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.text};
`

export function PoiDescription() {
  const selectedPoi = useSelectedPointOfInterest()
  const showPoiDescription = useStore((state) => state.showPoiDescription)
  const hidePoiDescription = useStore((state) => state.api.poiDescriptionOff)
  const showingProjectDescription = useStore(
    (state) => state.showProjectDescription
  )

  if (!showPoiDescription || !selectedPoi || showingProjectDescription) {
    return null
  }

  return (
    <PoiDescriptionContainer>
      <PoiHeader>
        <PoiName>{selectedPoi?.name}</PoiName>
        <CloseButton>
          <CloseButtonImage
            onClick={hidePoiDescription}
            src={closeIcon}
            alt="Close"
          />
        </CloseButton>
        <PoiLocation>{selectedPoi?.location}</PoiLocation>
      </PoiHeader>
      <PoiContent>
        <PoiText>{selectedPoi?.description}</PoiText>
        {selectedPoi?.notes
          ? selectedPoi?.notes.map((note, index) => (
              <PoiNote key={`${selectedPoi.id}-${index}`} note={note} />
            ))
          : null}
      </PoiContent>
      <PoiMenu />
    </PoiDescriptionContainer>
  )
}
