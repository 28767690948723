import styled from 'styled-components'
import { useStore } from '../state'

function LoadingProgressBar() {
  const modelLoadingProgress = useStore((state) => state.modelLoadingProgress)
  return (
    <LoadingProgressContainer>
      <ProgressBarContainer>
        <LoadingProgress style={{ width: `${modelLoadingProgress}%` }} />
      </ProgressBarContainer>
      <LoadingText>Loading experience...</LoadingText>
    </LoadingProgressContainer>
  )
}

const LoadingProgressContainer = styled.div`
  margin-top: 15px;
  width: 100%;
  color: ${(props) => props.theme.colors.primary};
`

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 5px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.background_buttons};
`
const LoadingProgress = styled.div`
  width: 0;
  height: 5px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.focus};
  margin-left: 0;
`

const LoadingText = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  padding: 10px;
`

export default LoadingProgressBar
