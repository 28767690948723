import React from 'react'
import { ProjectDescription } from './ProjectDescription'
import { Scene } from './Scene'
import { PoiDescription } from './PoiDescription'
import SceneSelector from './SceneSelector'

export function ProjectSceneView() {
  return (
    <>
      <Scene />
      <SceneSelector />
      <ProjectDescription />
      <PoiDescription />
    </>
  )
}
