import { useProgress } from '@react-three/drei'
import { useEffect } from 'react'
import { useStore } from '../state'

function useModelLoadingProgressUpdate() {
  const { progress, active } = useProgress()
  const setProgress = useStore((state) => state.api.setModelLoadingProgress)

  useEffect(() => setProgress(active, progress))
}

export default useModelLoadingProgressUpdate
