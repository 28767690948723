import styled from 'styled-components'
import { useProjectInformation } from '../hooks/useProjectInformation'
import infoOutlineIcon from '../assets/images/info-outline.svg'
import infoFilledIcon from '../assets/images/info-filled.svg'
import { useStore } from '../state'
import { AnalyticsEvent, logAnalyticsEvent } from '../services/firebase'

function ProjectDescriptionMenu() {
  const projectInformation = useProjectInformation()

  const showProjectDescription = useStore(
    (state) => state.showProjectDescription
  )
  const toggleDescription = useStore(
    (state) => state.api.toggleProjectDescription
  )

  const handleToggleDescription = () => {
    toggleDescription()
    logAnalyticsEvent(AnalyticsEvent.OpenProjectDescription)
  }

  return (
    <TitleContainer onClick={handleToggleDescription}>
      <Title>{projectInformation?.name}</Title>
      <InfoButton
        src={showProjectDescription ? infoFilledIcon : infoOutlineIcon}
        alt="info"
      />
    </TitleContainer>
  )
}

const TitleContainer = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  padding: 9px;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.theme.colors.border};
  border-radius: 12px;
  background-color: ${(props) => props.theme.colors.background_floating};

  :hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.theme.colors.background_floating_hover};
  }
`

const Title = styled.span`
  font-size: 18pt;
  line-height: 25px;
  font-weight: 600;
  margin-right: 24px;
  float: left;
  color: ${(props) => props.theme.colors.primary}; ;
`

const InfoButton = styled.img`
  height: 25px;
`

export default ProjectDescriptionMenu
