import React from 'react'
import styled, { ThemeProvider } from 'styled-components'

import { ProjectSceneView } from './components/ProjectSceneView'
import GlobalStyle from './GlobalStyle'
import theme from './theme'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppContainer>
        <GlobalStyle />
        <ProjectSceneView />
      </AppContainer>
    </ThemeProvider>
  )
}

const AppContainer = styled.div`
  text-align: center;
  background-color: ${(props) => props.theme.colors.background_app};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white;
`

export default App
