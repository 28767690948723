import { DefaultTheme } from 'styled-components'

const theme: DefaultTheme = {
  colors: {
    primary: '#232c85',
    focus: '#794dff',
    secondary_transparent: 'rgba(61, 73, 204, 0.7)',
    border: '#c7c6cc',
    background_buttons: '#ccc',
    text: '#9d9ea8',
    text_defocused: '#666',
    text_light: 'rgb(247, 245, 252)',
    text_explore_button_hover: '#f7f5fc',
    background_floating: 'rgba(247, 245, 252, 0.6)',
    background_floating_hover: 'rgba(247, 245, 252, 0.75)',
    background_overlay: 'rgba(247, 245, 252, 0.9)',
    background_poi_navigation: '#e6e6e6',
    background_explore_button: 'rgba(225, 223, 230, 0.8)',
    background_app: '#252525',
  },
  box_shadow: '1px 1px 6px 0 rgba(51, 51, 51, 0.5)',
}

export default theme
