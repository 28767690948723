import { useMemo, useState } from 'react'
import CameraControls from 'camera-controls'
import * as THREE from 'three'
import { useFrame, useThree } from '@react-three/fiber'
import { useSelectedPointOfInterest } from '../hooks/usePointOfInterests'
import useSceneConfig from '../hooks/useSceneConfig'
import { Poi } from '../services/projectService'

CameraControls.install({ THREE })

const fallbackCameraPosition = { x: 0, y: 1000, z: 0 }
const fallbackCameraTarget = { x: 0, y: 0, height: 0 }

function ViewControls() {
  const [cachedTargetPoi, setCachedTargetPoi] = useState<Poi | undefined>(
    undefined
  )
  const selectedPoi = useSelectedPointOfInterest()
  const sceneConfig = useSceneConfig()

  const isPoiChanged =
    (selectedPoi && !cachedTargetPoi) ||
    (!selectedPoi && cachedTargetPoi) ||
    (selectedPoi && cachedTargetPoi && selectedPoi.id !== cachedTargetPoi.id)
  if (isPoiChanged) {
    setCachedTargetPoi(selectedPoi)
  }

  const { camera, gl } = useThree()
  const controls = useMemo(
    () => new CameraControls(camera, gl.domElement),
    [camera, gl.domElement]
  )

  const cameraPosition =
    selectedPoi?.cameraPosition ??
    sceneConfig?.defaultCameraConfig.position ??
    fallbackCameraPosition
  const target =
    selectedPoi?.markerPosition ??
    sceneConfig?.defaultCameraConfig.target ??
    fallbackCameraTarget

  // Only change the camera lookAt if the target POI has changed
  // (to prevent resetting the position on model view change)
  if (isPoiChanged || !selectedPoi) {
    const enableTransitions = true
    controls.setLookAt(
      cameraPosition.x,
      cameraPosition.y,
      cameraPosition.z,
      target.x,
      target.height,
      target.y,
      enableTransitions
    )
  }

  

  controls.maxPolarAngle = Math.PI / 2.2
  controls.minDistance = 500
  controls.maxDistance = 3000
  controls.dollySpeed = 0.7

  // Need to test this with users
  // controls.dollyToCursor = true;

  return useFrame((state, delta) => {
    controls.update(delta)
    // Log camera position to console for debugging POI camera positions
    // console.log(camera.position)
  })
}

export default ViewControls
