import { useRef } from 'react'
import { useHelper } from '@react-three/drei'
import { DirectionalLightHelper } from 'three'

function Lights({ showLightHelpers }: { showLightHelpers?: boolean }) {
  const refLight = useRef()
  useHelper(refLight, DirectionalLightHelper, 5)

  const shadowMapSize = 2048
  const shadowCameraLimit = 1000

  return (
    <>
      <ambientLight intensity={0.8} color={0xffe9ec} />
      <directionalLight
        ref={showLightHelpers ? refLight : undefined}
        position={[200, 200, -100]}
        intensity={2}
        color={0xd7eeff}
        castShadow
        shadow-bias={-0.01}
        shadow-mapSize-width={shadowMapSize}
        shadow-mapSize-height={shadowMapSize}
        shadow-camera-left={-shadowCameraLimit}
        shadow-camera-right={shadowCameraLimit}
        shadow-camera-top={shadowCameraLimit}
        shadow-camera-bottom={-shadowCameraLimit}
        shadowCameraFar={1000}
      />
    </>
  )
}

export default Lights
