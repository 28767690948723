import { Suspense } from 'react'
import { Model } from './Model'
import useSceneConfig from '../hooks/useSceneConfig'
import LoadingProgressUpdate from './LoadingProgressUpdate'

function ModelManager() {
  const sceneConfig = useSceneConfig()
  const modelConfigs = sceneConfig ? sceneConfig.modelConfigs : []

  return (
    // TODO: provide a proper loading Suspense fallback
    <Suspense fallback={<LoadingProgressUpdate />}>
      {modelConfigs.map((modelConfig) => (
        <Model key={modelConfig.modelName} config={modelConfig} />
      ))}
    </Suspense>
  )
}

export default ModelManager
