import styled, { css } from 'styled-components'
import useSceneConfig from '../hooks/useSceneConfig'
import { useProjectInformation } from '../hooks/useProjectInformation'
import { SceneConfig } from '../services/projectService'
import { useStore } from '../state'
import { AnalyticsEvent, logAnalyticsEvent } from '../services/firebase'

function SceneSelector() {
  const projectInformation = useProjectInformation()
  const showingProjectDescription = useStore(
    (state) => state.showProjectDescription
  )

  // Do not render a scene selector until project information is available
  // Do not render a scene selector if there is only one scene config
  if (
    !projectInformation ||
    projectInformation.scenes.length <= 1 ||
    showingProjectDescription
  ) {
    return null
  }

  return (
    <SceneSelectorContainer>
      {projectInformation?.scenes.map((scene) => {
        return (
          <SceneSelectorButton
            key={`scene-selector-button${scene.id}`}
            sceneConfig={scene}
          />
        )
      })}
    </SceneSelectorContainer>
  )
}

type SceneSelectorButtonProps = {
  sceneConfig: SceneConfig
}

function SceneSelectorButton({ sceneConfig }: SceneSelectorButtonProps) {
  const selectScene = useStore((state) => state.api.selectScene)
  const handleSelectScene = (sceneConfig: SceneConfig) => {
    selectScene(sceneConfig.id)
    logAnalyticsEvent(AnalyticsEvent.SelectModelView, {
      scene_name: sceneConfig.name,
      scene_id: sceneConfig.id,
    })
  }
  const activeSceneConfig = useSceneConfig()
  const isSelected = activeSceneConfig?.id === sceneConfig.id

  return (
    <Button
      selected={isSelected}
      onClick={() => handleSelectScene(sceneConfig)}
    >
      {sceneConfig.name}
    </Button>
  )
}

const SceneSelectorContainer = styled.div`
  position: absolute;
  bottom: 12px;
  left: 12px;
  padding: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.theme.colors.border};
  border-radius: 12px;
  background-color: ${(props) => props.theme.colors.background_floating};

  :hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.theme.colors.background_floating_hover};
  }
`

// TODO: Refactor out a common Button component?
type ButtonProps = {
  selected?: boolean
}

const Button = styled.button<ButtonProps>`
  height: 39px;
  font-size: 16px;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  margin-right: 10px;
  background-color: transparent;
  border-color: transparent;
  color: ${(props) => props.theme.colors.primary};

  ${(props) =>
    props.selected &&
    css`
      background-color: ${(props) => props.theme.colors.focus};
      border-color: ${(props) => props.theme.colors.focus};
      color: ${(props) => props.theme.colors.text_light};
      box-shadow: ${(props) => props.theme.box_shadow};
    `};

  :hover {
    cursor: pointer;
    border-color: ${(props) => props.theme.colors.focus};
    box-shadow: ${(props) => props.theme.box_shadow};
  }

  :last-child {
    margin-right: 0;
  }
`

export default SceneSelector
