import firebase from 'firebase/app'
import 'firebase/analytics'

type FirebaseConfig = {
  apiKey: string | undefined
  authDomain: string | undefined
  projectId: string | undefined
  storageBucket: string | undefined
  messagingSenderId: string | undefined
  appId: string | undefined
  measurementId: string | undefined
}

export enum AnalyticsEvent {
  SelectPoi = 'select_poi',
  SelectModelView = 'select_model_view',
  OpenProjectDescription = 'open_project_description',
}

export function logAnalyticsEvent(
  eventType: AnalyticsEvent,
  eventParams?: { [key: string]: any }
) {
  if (isFirebaseConfigured()) {
    const analytics = firebase.analytics()
    analytics.logEvent(eventType, eventParams)
  }
}

// Initialise Firebase
export function initFirebase() {
  const firebaseConfig: FirebaseConfig = getFirebaseConfig()

  if (isFirebaseConfigured(firebaseConfig)) {
    firebase.initializeApp(firebaseConfig)
    firebase.analytics()
  } else {
    console.log('Firebase not configured')
  }
}

function getFirebaseConfig(): FirebaseConfig {
  return {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  }
}

function isFirebaseConfigured(firebaseConfig?: FirebaseConfig) {
  const config = firebaseConfig ?? getFirebaseConfig()
  return (
    config.apiKey &&
    config.authDomain &&
    config.projectId &&
    config.storageBucket &&
    config.messagingSenderId &&
    config.appId &&
    config.measurementId
  )
}
