import { Poi } from '../services/projectService'
import { useStore } from '../state'
import { useProjectInformation } from './useProjectInformation'
import useSceneConfig from './useSceneConfig'

export function usePointOfInterests(): Poi[] {
  const projectInformation = useProjectInformation()
  const sceneConfig = useSceneConfig()

  if (projectInformation && sceneConfig) {
    return projectInformation.pois.filter((poi) =>
      sceneConfig.poiIds.includes(poi.id)
    )
  }

  return []
}

export function useSelectedPointOfInterest(): Poi | undefined {
  const pois = usePointOfInterests()
  const selectedPoiId = useStore((state) => state.selectedPoiId)

  return pois.find((poi) => poi.id === selectedPoiId)
}
