import { SceneConfig } from '../services/projectService'
import { useStore } from '../state'
import { useProjectInformation } from './useProjectInformation'

function useSceneConfig(): SceneConfig | undefined {
  const projectInformation = useProjectInformation()
  const activeSceneId = useStore((state) => state.activeSceneId)

  if (projectInformation) {
    const sceneId = activeSceneId
      ? activeSceneId
      : projectInformation.defaultSceneId

    const scene = projectInformation.scenes.find(
      (scene) => scene.id === sceneId
    )

    return scene
  }
}

export default useSceneConfig
