import { getProjectPublicDirectoryPath } from '../assets'

export type Project = {
  id: string
  name: string
  description: string
  defaultSceneId: number
  scenes: SceneConfig[]
  pois: Poi[]
}

export type Poi = {
  id: number
  name: string
  location: string
  description: string
  notes?: Note[]
  markerPosition: MapPosition
  cameraPosition: Position
}

export type MapPosition = {
  x: number
  y: number
  height: number
}

export type Position = {
  x: number
  y: number
  z: number
}

export type Note = {
  title: string
  body: string
  images?: string[]
}

export type SceneConfig = {
  id: number
  name: string
  defaultCameraConfig: CameraConfig
  modelConfigs: ModelConfig[]
  poiIds: number[]
}

export type CameraConfig = {
  position: Position
  target: MapPosition
}

export type ModelConfig = {
  modelName: string
  hiddenObjects?: string[]
}

export async function getProjectInformation(): Promise<Project> {
  const path = `${getProjectPublicDirectoryPath()}project-data.json`
  const response = await fetch(path)
  const projectInfo = await response.json()
  return projectInfo
}
