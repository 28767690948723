import React from 'react'
import styled from 'styled-components'
import { Note } from '../services/projectService'
import { ProjectImage } from './ProjectImage'

export type PoiNoteProps = {
  note: Note
}

const Divider = styled.hr`
  width: 99%;
  color: ${(props) => props.theme.colors.primary};
`

const NoteTitle = styled.p`
  font-weight: 600;
  font-size: 15px;
  color: ${(props) => props.theme.colors.text};
`

const NoteText = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.text};
`

const ImageContainer = styled.div`
  width: 99%;
  padding-bottom: 10px;
`

export function PoiNote({ note }: PoiNoteProps) {
  return (
    <>
      <Divider />
      <NoteTitle>{note.title}</NoteTitle>
      <NoteText>{note.body}</NoteText>
      {note.images
        ? note.images.map((image, index) => (
            <ImageContainer key={`${image}-${index}`}>
              <ProjectImage image={image} />
            </ImageContainer>
          ))
        : null}
    </>
  )
}
